<template>
  <div class="container">
    <div :v-if="serviceDetail != {}" class="main">
      <img
        v-if="firstToActivate"
        src="./imgs/xuanzhong.png"
        class="icon"
      />
      <img v-else src="./imgs/xuanzhong-gray.png" class="icon" />
      <div class="status">
          {{firstToActivate ? '激活成功' : '激活码已使用'}}
      </div>
      <div v-if="!firstToActivate" class="row">
        <div class="item-name">激活时间</div>
        <div class="item-content">{{ activationDate() }}</div>
      </div>
      <div class="row">
        <div class="item-name">服务包名称</div>
        <div class="item-content">{{ serviceDetail.serviceName }}</div>
      </div>
      <div class="row">
        <div class="item-name">使用有效期</div>
        <div class="item-content">{{ parseDate() }}</div>
      </div>
      <div class="connect">
        请拨打服务热线
        <div @click="call" class="phone">{{ phone }}</div>
      </div>
      <div class="service">
        预约健康管理卡的各项服务。
      </div>
      <div style="flex: 1"></div>
    </div>
  </div>
</template>
<script>
import TextInput from "@/components/input/text-input.vue";
import { Field, Button } from "vant";
import Vue from "vue";
Vue.use(Field);
Vue.use(Button);
export default {
  components: {
    TextInput,
  },
  data() {
    return {
      phone: "15669951612",
      serviceDetail: {},
      firstToActivate: false,
    };
  },
  mounted() {
      
    console.log(this.$route.query, "query");
    this.serviceDetail = this.$route.query.data
    this.firstToActivate = this.serviceDetail.firstToActivate
  },

  methods: {
    didInput(e) {
      this.inputCompleted = e.length == 16;
      this.showErr = false;
      console.log(this.inputCompleted);
    },
    call() {
      window.location.href = `tel:${this.phone}`;
    },
    parseDate() {
      const { serviceStartTime, serviceEndTime } = this.serviceDetail;
      var startDate = new Date(serviceStartTime);
      var endDate = new Date(serviceEndTime);
    //   return `${startDate.getFullYear()}年${12}月${22}日 - ${endDate.getFullYear()}年${12}月${22}日`;
      return `${startDate.getFullYear()}年${startDate.getMonth() +
        1}月${startDate.getDate()}日 - ${endDate.getFullYear()}年${endDate.getMonth() +
        1}月${endDate.getDate()}日`;
    },
    activationDate() {
      const { activationTime } = this.serviceDetail;
      var startDate = new Date(activationTime);
      return `${startDate.getFullYear()}年${startDate.getMonth() +
        1}月${startDate.getDate()}日 ${this.full(startDate.getHours())}:${this.full(startDate.getMinutes())}`;
    },
    full(num) {
      if (num < 10) {
        return '0' + num
      }
      return num
    }
  },
};
</script>

<style lang="scss">
// body{
//   background: #fff;
// }
.container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #fff;
}
.main {
  width: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .icon {
    margin: 44px 0 19px;
    width: 80px;
    height: 80px;
  }
  .status {
    font-size: 40px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #040814;
    line-height: 56px;
    margin-bottom: 40px;
  }
  .row {
    margin-top: 20px;
    width: 100%;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #040814;
    line-height: 42px;
    display: flex;
    .item-name {
      margin: 0 25px 0 50px;
      width: 160px;
      white-space: nowrap;
      text-align: right;
    }
    .item-content {
      margin-right: 0px;
    }
  }
  .connect {
    margin-top: 92px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    line-height: 42px;

    .phone {
      margin-left: 14px;
      float: right;
      color: #12beae;
      text-decoration: underline;
    }
  }
  .service {
    margin-top: 5px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4f5265;
    line-height: 42px;
  }
}
</style>
